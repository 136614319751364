import React from "react"
import Layout from "../../components/layout/layout"
import InnerWrapper from "../../components/layout/innerWrapper"
import { Row, Col, Card } from "react-bootstrap"
import Breadcrumb from "react-bootstrap/Breadcrumb"
import { Link, useStaticQuery, graphql } from "gatsby"
import AboutSidebar from "../../components/layout/about-sidebar"
import SEO from "../../components/seo"

const CareersPage = () => {
  const data = useStaticQuery(graphql`
    {
      allJobPosting(sort: { fields: sort, order: DESC }) {
        nodes {
          content
          status
          jobTitle
          jobLocation
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title={`Careers, Employment & Open Positions | Walpole Outdoors`}
        description={`Interested in a career at Walpole Outdoors? See our open employment opportunities across a variety of positions and locations throughout the United States.`}
      />
      <InnerWrapper>
        <div className="page-hero"></div>

        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/about">About</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/about/careers">Careers</Link>
          </Breadcrumb.Item>
        </Breadcrumb>

        <Row className="generalcontent">
          <Col md={3}>
            <AboutSidebar />
          </Col>
          <Col md={9}>
            <h1 className="generalheader">Careers</h1>

            {data.allJobPosting.nodes.length === 0 ? (
              <div>We're sorry, there are no job openings at this time.</div>
            ) : null}

            {data.allJobPosting.nodes.map((job) => (
              <>
                <Card
                  className="careerscard"
                  style={{ padding: "15px", marginBottom: "10px" }}
                >
                  <Card.Title>{job.jobTitle}</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    {job.jobLocation}
                  </Card.Subtitle>
                  <hr
                    style={{
                      margin: "5px 0 20px",
                      borderTop: "1px solid rgb(22 105 56)",
                    }}
                  />
                  <Card.Text>
                    <div dangerouslySetInnerHTML={{ __html: job.content }} />
                  </Card.Text>
                </Card>
              </>
            ))}
          </Col>
        </Row>
      </InnerWrapper>
    </Layout>
  )
}

export default CareersPage
